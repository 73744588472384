import Body from "@/components/core-blocks/Body";
import Footer from "@/components/core-blocks/Footer";
import Header from "@/components/core-blocks/Header";
import Main from "@/components/core-blocks/Main";
import Sidebar from "@/components/core-blocks/Sidebar";
import Seo from "@/components/_SEO_";
import GlobalStyle from "@/styles/global";
import { lightTheme } from "@/styles/themes/lightTheme";
import * as React from "react";
import { ThemeProvider } from "styled-components";
import { GraphqlWPData, Lang, WPLink } from "../types";
import S from "@/components/layout-blocks/MainHero/MainHero.style";
import { graphql, useStaticQuery } from "gatsby";
import { LanguageProvider } from "../contexts/LanguageContext";

type NotFoundSettings = {
  pnf: {
    header: string;
    paragraph: string;
    image?: {
      altText: string;
      sourceUrl: string;
    };
    linkButtons: ReadonlyArray<{ link: WPLink }>;
  };
};

type Data = {
  siteSettingsSV: {
    pageNotFound: NotFoundSettings;
  };
  siteSettingsEN: {
    pageNotFound: NotFoundSettings;
  };
};

const PageNotFound: React.FC = () => {
  const data = useStaticQuery<GraphqlWPData<Data>>(graphql`
    {
      wp {
        siteSettingsSV {
          pageNotFound {
            pnf {
              header
              image {
                altText
                sourceUrl
              }
              paragraph
              linkButtons {
                link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
        siteSettingsEN {
          pageNotFound {
            pnf {
              header
              image {
                altText
                sourceUrl
              }
              paragraph
              linkButtons {
                link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `).wp[`siteSettings${process.env.GATSBY_LANGUAGE}`].pageNotFound.pnf;

  return (
    <ThemeProvider theme={lightTheme}>
      <LanguageProvider lang={process.env.GATSBY_LANGUAGE || Lang.en}>
        <GlobalStyle />
        <Sidebar title="/404/" />
        <Body>
          <Header />
          <Main>
            <Seo
              title={
                process.env.GATSBY_LANGUAGE === "SV"
                  ? "Sidan kunde inte hittas"
                  : "Page not found"
              }
              image={data.image?.sourceUrl || ""}
            />
            <S.Section id="main-hero">
              <S.Overlay />
              <S.MainInner>
                <S.Content>
                  <S.Header>{data.header}</S.Header>
                  <S.Paragraph>{data.paragraph}</S.Paragraph>
                  <S.Repeater>
                    {data.linkButtons.map((btn, i) => (
                      <S.ButtonLink
                        key={i}
                        href={btn.link.url}
                        target={btn.link.target}
                        color={"light"}
                      >
                        {btn.link.title}
                      </S.ButtonLink>
                    ))}
                  </S.Repeater>
                </S.Content>
                <S.Aside>
                  {data.image && (
                    <S.Image
                      src={data.image.sourceUrl}
                      alt={data.image.altText}
                    />
                  )}
                </S.Aside>
              </S.MainInner>
            </S.Section>
          </Main>
          <Footer />
        </Body>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default PageNotFound;
